import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import '../../../../App.css';
import logo from '../../../../img_logo.png'
import iconApp from '../../../../Icon-App-1024.png';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import axios from 'axios';
import {captureException, addBreadcrumb} from "../../../../utils/sentry"

function Input() {
  const history = useHistory();

  const [mailAddress, setMailAddress] = useState()
  const handleChangeMailAddress = (event) => {
    setMailAddress(event.target.value)
  };
  const [errorMsg, setErrorMsg] = useState()
  const [disabled, setDisabled] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    if(disabled) {
      return
    }
    setDisabled(true)
    addBreadcrumb(
      'axios.post',
      "password-reset call", {
      Field: "accept",
      Arguments: {mailAddress: mailAddress}
    });
    await axios.post("https://app-user.totono.app/password/reset", {
      Field: "accept",
      Arguments: {mailAddress: mailAddress}
    })
      .then((results) => {
        if (results.data.statusCode === 200) {
          history.push("/password/reset/accept/success")
        }
        if (results.data.statusCode === 404) {
          setDisabled(false)
          setErrorMsg("お客様のアカウントが見つかりませんでした。\n入力情報をご確認のうえ、もう一度お試しください。")
        }
        if (results.data.statusCode === 500) {
          setDisabled(false)
          setErrorMsg("エラーが発生しました。\n時間を置いてもう一度お試しください。")
        }
      }).catch(() => {
        setDisabled(false)
        captureException();
        console.log('通信に失敗しました。');
      });
  };
  return (
    <div className="App">
      <header className="App-header">
        <Grid
          container
          style={{backgroundColor: '#ffffff', paddingTop: 20, paddingBottom: 20}}
        >
          <Grid
            item
            xs={12}
          >
            <img src={logo} alt="logo"/>
          </Grid>
        </Grid>
        <Grid
          container
          style={{backgroundColor: '#ffffff', marginTop: 9, paddingTop: 38, paddingBottom: 38}}
        >
          <Grid
            item
            xs={12}
          >
            <Typography component="h2" variant="h6">
              パスワード再設定
            </Typography>
            <img src={iconApp} alt="iconApp" width="96" height="96"
                 style={{marginTop: 24, marginBottom: 24, borderRadius: 24}}/>
            <Typography variant="body1" component="h2">
              パスワード再設定URLを送信します<br/>
              登録済みのメールアドレスを入力して、送信ボタンを押してください<br/>
              ※パスワード再設定は、セキュリティの関係上24時間以内にお手続きを完了してください
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            {errorMsg != null && errorMsg && (
              <Typography variant="body1" component="h2" color={"secondary"} style={{whiteSpace: "pre-line"}}>
                {errorMsg}
              </Typography>
            )}
          </Grid>
          <form
            onSubmit={handleSubmit}
            style={{width: '640px', margin: 'auto'}}
          >
            <Grid
              item
              xs={12}
              style={{marginTop: 40}}
            >
              <TextField
                required
                type='email'
                label="メールアドレス"
                value={mailAddress}
                onChange={handleChangeMailAddress}
                style={{width: '84%'}}/>
            </Grid>
            <Grid
              item
              xs={12}
              style={{marginTop: 40}}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={disabled}
                style={{width: '72%', height: '48px', color: '#ffffff', backgroundColor: '#6eb9a5', fontSize: '1.3rem'}}
              >
                送信
              </Button>
            </Grid>
          </form>
        </Grid>
      </header>
    </div>
  );
}

export default Input;
