import * as Sentry from "@sentry/react";

export function captureException(err) {
  console.debug("captureException")
  Sentry.captureException(err);
}

export function captureMessage(msg) {
  console.debug("captureMessage")
  Sentry.captureMessage(msg);
}

export function addBreadcrumb(category, message, data) {
  Sentry.addBreadcrumb({
    category: category,
    message: message,
    data: data
  });
}
